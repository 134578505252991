import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { loanTypeArray } from "pages/MyZone/MyZoneApply/Headers";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
export const ApproveLoan = ({ viewLoanDetails, setShowApprove }) => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const today = dayjs().format("YYYY-MM-DD");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [loanType, setLoanType] = useState("");
  const [descriptions, setDescriptions] = useState("");
  const [loanAmount, setLoanAmount] = useState(
    viewLoanDetails.monthly_installment
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [emiAmount, setEmiAmount] = useState("");
  const [loanInterestPercentage, setLoanInterestPercentage] = useState("");
  const [tenureMonthCount, setTenureMonthCount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [startDate, setStartDate] = useState(today);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}loans/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user: userDetails.id,
          principal_amount: data.loan_amount,
          interest_rate: data.interest,
          tenure_months: data.tenure,
          repayment_start_date: data.from_date,
          monthly_installment: data.emi_amount,
          description: data.descriptions,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          "Loan request received! You'll get a notification when it's approved."
        );
        clearAllData();
        setStartDate(today);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const clearAllData = () => {
    setDescriptions("");
    setLoanAmount("");
    setEmiAmount("");
    setStartDate(today);
    setLoanInterestPercentage("");
    setTenureMonthCount("");
    reset();
  };

  const handleLoanType = (event, data) => {
    if (data) {
      setValue("loan_type", data?.label);
      setLoanType(data?.label);
    } else {
      setValue("loan_type", null);
      setLoanType("");
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const handleCloseRejectDialogBox = () => {
    setOpenRejectDialog(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid container>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setShowApprove(false);
            }}
          />
          &nbsp;&nbsp;
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 0.5 }}
          >
            Approve Loan
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{ border: `2px solid ${themes.sidebarDivider}`, borderRadius: 2 }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              padding: 1,
              backgroundColor: themes.sidebarDivider,
            }}
          >
            <Mui.Typography
              sx={{ paddingLetf: 2, fontSize: 20, fontWeight: "bold" }}
            >
              Loan Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Employee Id & Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.TextField
                      value="32875434"
                      size="small"
                      disabled={true}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={8} sx={{ paddingLeft: 1 }}>
                    <Mui.TextField
                      value={viewLoanDetails?.user_name}
                      size="small"
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Department
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.tenure_months}
                  size="small"
                  disabled={true}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={loanTypeArray}
                  selectedValue={loanType}
                  rules={{
                    ...register("loan_type", {
                      required: "Loan type is required",
                    }),
                  }}
                  updateDataFunction={handleLoanType}
                  placeHolder="Please select the loan type"
                  errorMessage={
                    errors?.loan_type?.message ? errors?.loan_type?.message : ""
                  }
                  textBoxError={errors?.loan_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.principal_amount}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.interest_rate}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.interest_rate}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Tenure(Month)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  value={viewLoanDetails?.tenure_months}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Applied Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  type="date"
                  value={viewLoanDetails?.tenure_months}
                  disabled={true}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Reason for loan
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: "100%",
                  }}
                  label="Provide a reason for loan"
                  multiline
                  rows={3}
                  value={viewLoanDetails?.reason}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={() => {
                  setOpenRejectDialog(true);
                }}
                actionName="Reject"
                typeName="button"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(true);
                }}
                actionName="Approve"
                typeName="button"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Approve Loan"
      ></GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={handleCloseRejectDialogBox}
        open={openRejectDialog}
        title="Reject Loan"
      ></GlobalDialogBox>
    </form>
  );
};
