import React, { useState, useEffect } from "react";
import { MoreVert } from "@mui/icons-material";
import * as Mui from "@mui/material";
import { DialogBox } from "../DialogBox";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { teamZoneLoanHistory } from "../Headers";
const keys = [
  "principal_amount",
  "interest_rate",
  "tenure_months",
  "repayment_start_date",
  "repayment_end_date",
  "status",
  "user_name",
  "repayment_date",
  "disbursement_date",
  "description",
  "reason",
];

export const Approve = ({
  departmentId,
  departmentChange,
  setDepartmentChange,
  loanScreen,
  setReloadData,
  setViewLoanDetails,
  setShowApprove,
}) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectedReasonValidation, setRejectedReasonValidation] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    result: loanDetails,
    loading,
    setLoad: reCallApi,
  } = useFetch(
    `${domain}loans/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}`
  );

  const handleDeleteCancel = () => {
    setRejectReason("");
    setOpenRejectDialog(false);
    setRejectedReasonValidation("");
  };

  const onSubmit = async () => {
    if (rejectReason.trim() === "") {
      setRejectedReasonValidation("Reason");
      return;
    } else if (rejectReason.length < 5) {
      setRejectedReasonValidation("Less than 5 character");
      return;
    }

    try {
      await rejectLoan();
      setOpenRejectDialog(false);
      setRejectedReasonValidation("");
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    if (departmentChange === true && loanScreen === "Approve") {
      reCallApi(true);
      setDepartmentChange(false);
    }
  }, [departmentChange, loanScreen, reCallApi, setDepartmentChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const approveLoan = async (item) => {
    try {
      const response = await fetch(`${domain}loans/${item.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({ status: "Approved" }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Loan Approved");
        reCallApi(true);
        setReloadData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const rejectLoan = async () => {
    if (!selectedItem) return;
    try {
      const response = await fetch(`${domain}loans/${selectedItem.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({ status: "Rejected", reason: rejectReason }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Loan rejected");
        reCallApi(true);
        setReloadData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanData(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    if (loanDetails?.length !== 0 || loanDetails?.length === 0) {
      const filteredResults = loanDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setLoanData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, loanDetails]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow
              sx={{
                "& .MuiTableCell-root": {
                  height: "15px",
                  padding: "6px 5px",
                },
              }}
            >
              {teamZoneLoanHistory.map((head) => (
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">{head.name}</Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>

          <Mui.TableBody>
            {loanData.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              loanData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "10px",
                        padding: "3px 5px",
                      },
                    }}
                  >
                    {teamZoneLoanHistory.map((head) =>
                      head.name !== "Action" ? (
                        <Mui.TableCell>
                          {item[head.key] ? item[head.key] : "N/A"}
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={(e) => {
                              handleMenuOpen(e, index);
                            }}
                          >
                            <MoreVert
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Mui.MenuItem
                              onClick={() => {
                                setViewLoanDetails(item);
                                setShowApprove(true);
                              }}
                            >
                              View
                            </Mui.MenuItem>
                          </Mui.Menu>
                          {/* <Mui.Stack direction="row">
                            <Mui.Tooltip title="Approve" arrow>
                              <Mui.IconButton onClick={() => approveLoan(item)}>
                                <AddTaskIcon
                                  sx={{
                                    color: themes.primaryIndicator,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </Mui.Tooltip>
                            <Mui.Tooltip title="Reject" arrow>
                              <Mui.IconButton
                                onClick={() => {
                                  setOpenRejectDialog(true);
                                  setSelectedItem(item);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: themes.redColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                            </Mui.Tooltip>
                          </Mui.Stack> */}
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {loanData.length > 20 && (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={loanData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>
      <DialogBox
        open={openRejectDialog}
        onClose={handleDeleteCancel}
        title="Reason for Rejection"
      >
        <Mui.TextField
          multiline
          minRows={3}
          sx={{
            marginTop: 1,
            width: "95%",
            marginLeft: 2,
            marginRight: "auto",
          }}
          placeholder="please enter reason of rejection"
          onChange={(e) => setRejectReason(e.target.value)}
        />
        {rejectedReasonValidation === "Reason" ? (
          <Mui.Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 13, paddingLeft: 3, paddingTop: 0.5 }}
          >
            Please enter the rejected reason
          </Mui.Typography>
        ) : null}
        {rejectedReasonValidation === "Less than 5 character" ? (
          <Mui.Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 13, paddingLeft: 3, paddingTop: 0.5 }}
          >
            Minium 5 character required
          </Mui.Typography>
        ) : null}

        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={8}></Mui.Grid>
          <Mui.Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={onSubmit}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </DialogBox>
    </React.Fragment>
  );
};
