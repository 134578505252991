import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { themes } from "services/constants";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import comLogo from "../../../assets/img/brand/company_logo.png";
import "./CustomStyles.css";
import * as Mui from "@mui/material";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Popover,
} from "@mui/material";
import { Loader } from "components/Loader";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { dateFormat } from "services/constants";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import sessionHandling from "services/utils/notificationUtils";
import { getContrastingTextColor } from "services/utils/colorChecker";

export const Calendar = () => {
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiFetchedEvents, setApiFetchedEvents] = useState([]);
  const [getCompanyLeaves, setGetCompanyLeaves] = useState(true);
  const [loading, setLoading] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  const Avatar_hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 8) - hash + char;
      hash = hash & hash;
    }
    return hash.toString(16).slice(2, 8);
  };
  useEffect(() => {
    const fetchCalendarData = async () => {
      setLoading(true);
      try {
        const response1 = await fetch(
          `${domain}leavesummaryadmin/?department_id=${userDetails.department_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );

        const response2 = await fetch(`${domain}companyleave/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res1 = await response1.json();
        const res2 = await response2.json();
        if (response1.ok && response2.ok) {
          const [companyLeaveResponse, leaveSummaryAdminResponse] =
            await Promise.all([res2, res1]);
          const companyLeaveEvents = companyLeaveResponse.map((leave) => {
            return {
              id: `company_leave_${leave.id}`,
              title: `${leave.leave_type}`,
              start: new Date(
                leave.date.split("-")[2],
                leave.date.split("-")[1] - 1,
                leave.date.split("-")[0]
              ),
              color: "#4C9D97",
            };
          });
          const adminLeaveEvents = leaveSummaryAdminResponse.leave_count
            .flatMap((user) =>
              user.leave_requests.map((request) => {
                let firstLetter = user.user_name.charAt(0).toUpperCase();
                const from_Date = new Date(
                  request.from_date.split("-").reverse().join("-")
                );
                const end_Date = new Date(
                  request.to_date.split("-").reverse().join("-")
                );

                if (
                  request.status === "Completed" ||
                  request.status === "Approved"
                ) {
                  return {
                    id: `admin_leave_${request.id}`,
                    title: `${user.user_name}`,
                    leave_type: `${request.leave_type}`,
                    start: from_Date,
                    end: end_Date,
                    description: request.description,
                    designation: user.designation,
                    status: request.status,
                    half_day: request.half_day,
                    which_half: request.which_half,
                    image_url: `${user.image_url}`,
                    color: request.leave_color,
                    firstLetter,
                  };
                }
                return null;
              })
            )
            .filter(Boolean);
          setApiFetchedEvents([...companyLeaveEvents, ...adminLeaveEvents]);
          setLoading(false);
        } else {
          if (!response1.ok) {
            if (response1.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res1.error);
            }
          }
          if (!response2.ok) {
            if (response2.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res2.error);
            }
          }
        }
      } catch (error) {
        setLoading(false);
        showToast("error", error.message);
      }
    };
    if (getCompanyLeaves) {
      fetchCalendarData();
      setGetCompanyLeaves(false);
    }
  }, [
    domain,
    getCompanyLeaves,
    token,
    userDetails.department_id,
    userDetails.id,
  ]);

  const handleEventClick = (event, anchorEl) => {
    setAnchorEl(anchorEl);
    setSelectedEvent(event);
  };

  const handleCloseCard = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const handleDayCellDidMount = (arg) => {
    if (arg.dateStr === today) {
      arg.el.classList.add("today-custom");
    }
  };
  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <Mui.Grid sx={{ paddingTop: 2.5, paddingRight: 1 }}>
          <Card m="20px" sx={{ boxShadow: 5 }}>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseCard}
              anchorOrigin={{
                vertical: "left",
                horizontal: "bottom",
                paddingRight: 10,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  marginLeft: "-25px",
                },
              }}
            >
              {selectedEvent && (
                <>
                  <CardContent sx={{ paddingLeft: 2 }}>
                    <Box sx={{ backgroundColor: "lightgray" }}>
                      <Mui.Stack direction="row">
                        <Typography
                          sx={{ ml: 12.3 }}
                          fontWeight={"bold"}
                          fontSize={20}
                        >
                          Leave Details
                        </Typography>
                        <div style={{ justify: "flex-end" }}>
                          <Button
                            onClick={handleCloseCard}
                            color="error"
                            size="small"
                            sx={{ marginLeft: 10 }}
                          >
                            <CloseIcon />
                          </Button>
                        </div>
                      </Mui.Stack>
                    </Box>
                    <Mui.Grid
                      container
                      sx={{ paddingTop: 2, paddingBottom: 2 }}
                    >
                      <Mui.Grid xs={2}>
                        {selectedEvent.extendedProps?.firstLetter ? (
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              backgroundColor:
                                "#" + Avatar_hashCode(selectedEvent.title),
                            }}
                            src={selectedEvent.extendedProps?.image_url}
                            alt={selectedEvent.extendedProps?.firstLetter}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              marginLeft: 1,
                            }}
                          >
                            <img
                              src={comLogo}
                              alt=""
                              style={{ height: 28, width: 28 }}
                            />
                          </Avatar>
                        )}
                      </Mui.Grid>
                      <Mui.Grid xs={10} sx={{ paddingTop: -1 }}>
                        {selectedEvent.extendedProps?.firstLetter ? (
                          <Typography fontWeight={"light"} fontSize={20}>
                            {selectedEvent.title}
                            <Typography fontWeight={"light"} fontSize={20}>
                              {selectedEvent.extendedProps?.designation}
                            </Typography>
                          </Typography>
                        ) : (
                          <Typography fontWeight={"light"} fontSize={17}>
                            {selectedEvent.title}
                            <Typography
                              sx={{ marginTop: 0 }}
                              fontWeight={"light"}
                              fontSize={17}
                            >
                              Company Holiday
                            </Typography>
                          </Typography>
                        )}
                      </Mui.Grid>
                    </Mui.Grid>

                    {selectedEvent.extendedProps?.firstLetter ? (
                      moment(
                        new Date(selectedEvent._instance.range.start)
                      ).format(dateFormat) ===
                      moment(
                        new Date(selectedEvent._instance.range.end)
                      ).format(dateFormat) ? (
                        <>
                          <Typography
                            sx={{ marginLeft: 25, marginTop: -2, color: "red" }}
                          >
                            {moment(
                              new Date(selectedEvent._instance.range.start)
                            ).format(dateFormat)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{ marginLeft: 15, marginTop: -2, color: "red" }}
                          >
                            {moment(
                              new Date(selectedEvent._instance.range.start)
                            ).format(dateFormat)}
                          </Typography>
                          <Typography
                            fontWeight={"bold"}
                            sx={{ marginLeft: 26, marginTop: -3 }}
                          >
                            To
                          </Typography>{" "}
                          <Typography
                            sx={{ marginLeft: 29, marginTop: -3, color: "red" }}
                          >
                            {moment(
                              new Date(selectedEvent._instance.range.end)
                            ).format(dateFormat)}
                          </Typography>
                        </>
                      )
                    ) : moment(
                        new Date(selectedEvent._instance.range.start)
                      ).format(dateFormat) ===
                      moment(
                        new Date(selectedEvent._instance.range.end)
                      ).format(dateFormat) ? (
                      <>
                        <Typography
                          sx={{ marginLeft: 25, marginTop: -2, color: "red" }}
                        >
                          {moment(
                            new Date(selectedEvent._instance.range.start)
                          ).format(dateFormat)}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{ marginLeft: 15, marginTop: -2, color: "red" }}
                        >
                          {moment(
                            new Date(selectedEvent._instance.range.start)
                          ).format(dateFormat)}
                        </Typography>
                        <Typography
                          fontWeight={"bold"}
                          sx={{ marginLeft: 26, marginTop: -3 }}
                        >
                          To
                        </Typography>{" "}
                        <Typography
                          sx={{ marginLeft: 29, marginTop: -3, color: "red" }}
                        >
                          {moment(
                            new Date(selectedEvent._instance.range.end)
                          ).format(dateFormat)}
                        </Typography>
                      </>
                    )}
                    {selectedEvent.extendedProps?.half_day && (
                      <Typography sx={{ marginLeft: 22.3 }}>
                        Half Day -{" "}
                        {selectedEvent.extendedProps?.which_half === "1st"
                          ? "Morning"
                          : "Noon"}
                      </Typography>
                    )}

                    <Box
                      sx={{
                        backgroundColor: selectedEvent.backgroundColor
                          ? selectedEvent.backgroundColor
                          : themes.outTap,
                        color: getContrastingTextColor(
                          selectedEvent?.backgroundColor
                            ? selectedEvent?.backgroundColor
                            : themes.outTap
                        ),
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: 3,
                          marginBottom: -1.5,
                          marginTop: 1,
                          color: getContrastingTextColor(
                            selectedEvent?.backgroundColor
                              ? selectedEvent?.backgroundColor
                              : themes.outTap
                          ),
                        }}
                      >
                        {selectedEvent.extendedProps?.leave_type}
                      </Typography>
                    </Box>
                  </CardContent>
                </>
              )}
            </Popover>

            <FullCalendar
              height="85vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: null,
              }}
              initialView="dayGridMonth"
              dayMaxEvents={true}
              eventLimit={true}
              moreLinkClick={(info) => info.jsEvent.preventDefault()}
              eventsSet={(events) => events}
              events={apiFetchedEvents}
              eventDisplay="block"
              eventContent={(eventInfo) => (
                <div
                  onClick={(e) =>
                    handleEventClick(eventInfo.event, e.currentTarget)
                  }
                >
                  <Typography
                    sx={{
                      marginLeft: 5,
                      color: getContrastingTextColor(
                        eventInfo?.backgroundColor
                          ? eventInfo?.backgroundColor
                          : themes.outTap
                      ),
                    }}
                  >
                    {eventInfo.event.title}
                  </Typography>

                  {eventInfo.event.extendedProps?.firstLetter ? (
                    <Avatar
                      sx={{
                        marginTop: -3,
                        width: 22,
                        height: 22,
                        marginLeft: 1,
                        backgroundColor:
                          "#" + Avatar_hashCode(eventInfo.event.title),
                      }}
                      src={eventInfo.event.extendedProps?.image_url}
                      alt={eventInfo.event.extendedProps?.firstLetter}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        marginTop: -3,
                        width: 25,
                        height: 25,
                        marginLeft: 1,
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={comLogo}
                        alt=""
                        style={{ height: 28, width: 28 }}
                      />
                    </Avatar>
                  )}
                </div>
              )}
              dayCellDidMount={handleDayCellDidMount}
            />
          </Card>
        </Mui.Grid>
      )}
    </React.Fragment>
  );
};
