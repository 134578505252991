export const loanHistory = [
  { name: "Date", key: "disbursement_date" },
  //   { name: "Employee Name" },
  { name: "Loan Amount", key: "principal_amount" },
  { name: "Tenure", key: "tenure_months" },
  { name: "Interest", key: "interest_rate" },
  { name: "Installment Amount", key: "monthly_installment" },
  { name: "Loan Type", key: "tenure_months" },
  { name: "Status", key: "status" },
  { name: "Action" },
];

export const viewTableData = [
  { name: "Payment Date", key: "payment_month" },
  { name: "Interest", key: "interest" },
  { name: "Installment Amount", key: "amount" },
  { name: "Total Amount", key: "total_amount" },
  { name: "Status", key: "status" },
  { name: "Balance", key: "balance" },
];

export const loanTypeArray = [
  {
    label: "Personal Loan",
  },
  {
    label: "Home Loan",
  },
  {
    label: "Vehicle Loan",
  },
  {
    label: "Education Loan",
  },
  {
    label: "Medical Loan",
  },
];

export const interestType = [
  {
    label: "Flat Rate",
  },
  {
    label: "Reducing Banlance",
  },
];
