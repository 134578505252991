import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Weekly } from "./Weekly";
import { themes } from "services/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { setPlanShiftScreen } from "services/Redux/TeamZone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { startOfMonth, endOfMonth } from "date-fns";
import moment from "moment";
import { Loader } from "components/Loader";
import { defaultAllSelection } from "services/constants";
import { showToast } from "services/utils/Status";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
import { Monthly } from "./Monthly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setGlobalSearchValue } from "services/Redux/userToken";

export const PlanShift = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { planShiftScreen } = useSelector((state) => state.teamZoneReducer);
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const [dataVisualMethod, setDataVisualMethod] = useState(planShiftScreen);
  const [shiftData, setShiftData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [listOfDate, setListOfDate] = useState([]);
  const [monthlyselectedDate, setMonthlySelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(new Date(), "yyyy-MM");
  const [loading, isLoading] = useState(false);
  const [fetchWeekly, setFetchWeekly] = useState(false);
  const [presentScreen, setPresentScreen] = useState(false);
  const [weeklyShiftList, setWeeklyShiftList] = useState([]);
  const [monthlyShiftList, setMonthlyShiftList] = useState([]);
  const [filterChanges, setFilterChanges] = useState(false);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const minDate = new Date(2020, 0, 1);
  const { result: departmentData } = useFetch(
    `${domain}get-department/?user_id=${userDetails.id}`
  );

  useEffect(() => {
    if (presentScreen !== true && departmentData?.length !== 0) {
      dispatch(setDepartmentName(departmentData[0]?.department_name));
      dispatch(setDepartmentId(departmentData[0]?.id));
    } else {
      dispatch(setDepartmentName(departmentName));
      dispatch(setDepartmentId(departmentId));
    }
  }, [
    dataVisualMethod,
    departmentData,
    departmentId,
    departmentName,
    dispatch,
    presentScreen,
  ]);

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) +
      startDate.getDay();
    const weekNumber = Math.ceil(days / 7);
    const weekValue = `${currentDate.getFullYear()}-W${weekNumber
      .toString()
      .padStart(2, "0")}`;
    return weekValue;
  };
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek);

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const handleMonthlyDateOnChange = (date) => {
    setPresentScreen(true);
    setMonthlySelectedDate(date);
  };

  const handleWeeklyDateOnChange = (event) => {
    setPresentScreen(true);
    setSelectedWeek(event.target.value);
  };

  useEffect(() => {
    if (selectedWeek && departmentId !== 99999 && dataVisualMethod) {
      if (dataVisualMethod === "Weekly") {
        const startDate = moment(selectedWeek).startOf("isoWeek").toDate();
        const endDate = moment(selectedWeek).endOf("isoWeek").toDate();
        const dates = getWeekDays(startDate);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(startDate).format("YYYY-MM-DD"));
        setEndDate(moment(endDate).format("YYYY-MM-DD"));
        if (startDate !== "" && endDate !== "") {
          setFetchWeekly(true);
        }
      } else {
        const start = startOfMonth(monthlyselectedDate);
        const end = endOfMonth(monthlyselectedDate);
        const dates = getMonthDays(start);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(start).format("YYYY-MM-DD"));
        setEndDate(moment(end).format("YYYY-MM-DD"));
        if (start !== "" && end !== "") {
          setFetchWeekly(true);
        }
      }
    }
  }, [
    dataVisualMethod,
    departmentId,
    selectedWeek,
    monthlyselectedDate,
    startDate,
    endDate,
  ]);

  const getMonthDays = (startDate) => {
    const days = [];
    const year = startDate.getFullYear();
    const month = startDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let currentDate = firstDayOfMonth;

    while (currentDate <= lastDayOfMonth) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  useEffect(() => {
    const formatDate = () => {
      if (monthlyselectedDate) {
        const formattedDate =
          monthlyselectedDate?.getFullYear() +
          "-" +
          ("0" + (monthlyselectedDate?.getMonth() + 1)).slice(-2);
        setFormattedDate(formattedDate);
      }
    };
    formatDate();
    if (
      formattedDate &&
      departmentId !== 99999 &&
      dataVisualMethod === "Monthly"
    ) {
      setFetchWeekly(true);
    }
  }, [dataVisualMethod, departmentId, formattedDate, monthlyselectedDate]);

  const updateShiftBasedOnRange = async (userId, shift_assignments) => {
    try {
      const response = await fetch(`${domain}shift_assignments/daily/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_assignments: [
            {
              user: userId,
              daily_assignments: shift_assignments,
            },
          ],
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Shift assigned successfully");
        setFetchWeekly(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const fetchShiftData = async () => {
      try {
        const response = await fetch(
          `${domain}department_filter/?id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const shifts = res?.reduce((key, value) => {
            return [
              ...key,
              ...value.department_shift_categories.reduce(
                (keys, values) => [...keys, ...values.shifts],
                []
              ),
            ];
          }, []);
          setShiftData([]);
          let shiftDataWithLabel = [];
          shiftDataWithLabel = shifts?.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          const allShifts = {
            id: defaultAllSelection,
            label: "Weekoff",
            name: "Weekoff",
          };
          setShiftData(
            [allShifts, ...shiftDataWithLabel].filter(
              (obj, index, self) =>
                index === self?.findIndex((t) => t?.id === obj?.id)
            )
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (shiftData?.length === 0 && departmentName !== "All") {
      fetchShiftData();
    }
  }, [
    dataVisualMethod,
    departmentId,
    departmentName,
    domain,
    shiftData?.length,
    token,
  ]);

  useEffect(() => {
    const fetchWeeklyShiftList = async () => {
      isLoading(true);
      try {
        const response = await fetch(
          `${domain}daily_user_shift_assignments/?department_id=${departmentId}&from_date=${startDate}&to_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setMasterDataForSearch([]);
          dataVisualMethod === "Monthly"
            ? setMonthlyShiftList([])
            : setWeeklyShiftList([]);
          dataVisualMethod === "Monthly"
            ? setMonthlyShiftList(res?.data)
            : setWeeklyShiftList(res?.data);
          setMasterDataForSearch(res?.data);
          isLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        isLoading(false);
      }
    };
    if (fetchWeekly && departmentId !== "") {
      fetchWeeklyShiftList();
      setFetchWeekly(false);
    }
  }, [
    departmentId,
    domain,
    endDate,
    fetchWeekly,
    startDate,
    dataVisualMethod,
    token,
  ]);

  useEffect(() => {
    const FilterWeeklyShiftData = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setWeeklyShiftList(FilterWeeklyShiftData());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  useEffect(() => {
    const FilterMonthlyShiftData = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    setMonthlyShiftList(FilterMonthlyShiftData());
    setFilterChanges(true);
  }, [globalSearchValue, masterDataForSearch]);

  return departmentName === "All" ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 3 }}>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            fullWidth
            value={
              departmentData?.find(
                (option) => option?.department_name === departmentName
              ) || {
                department_name: departmentName !== "All" ? departmentName : "",
              }
            }
            options={departmentData?.filter(
              (value) => value.department_name !== "All"
            )}
            getOptionLabel={(option) =>
              option?.department_name ? option?.department_name : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.department_name === value.department_name
            }
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.department_name
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.department_name}</li>;
            }}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            onChange={async (event, value) => {
              setPresentScreen(true);
              setDepartmentId("");
              setDepartmentName("");
              setShiftData([]);
              if (value) {
                dispatch(setDepartmentName(value.department_name));
                dispatch(setDepartmentId(value.id));
                localStorage.setItem("approveLeaveDepartmentId", value.id);
                localStorage.setItem(
                  "approveLeaveDepartmentName",
                  value.department_name
                );
                setFetchWeekly(true);
              }
            }}
            sx={{ minWidth: "100%" }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                sx={{ background: "white", padding: 0.5 }}
                placeholder="Select department name"
                label="Select Department"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          <Mui.ButtonGroup
            size="medium"
            fullWidth
            sx={{ minWidth: "100%" }}
            aria-label="Large button group"
          >
            <Mui.Button
              variant={
                dataVisualMethod === "Monthly" ? "contained" : "outlined"
              }
              sx={{
                background:
                  dataVisualMethod === "Monthly"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Monthly"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
              onClick={() => {
                setPresentScreen(true);
                dispatch(setDepartmentName(departmentName));
                dispatch(setDepartmentId(departmentId));
                setDataVisualMethod("Monthly");
                dispatch(setPlanShiftScreen("Monthly"));
                localStorage.setItem("planShiftScreen", "Monthly");
              }}
            >
              Monthly
            </Mui.Button>
            <Mui.Button
              variant={dataVisualMethod === "Weekly" ? "contained" : "outlined"}
              sx={{
                background:
                  dataVisualMethod === "Weekly"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Weekly"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
              onClick={() => {
                setPresentScreen(true);
                setSelectedWeek(getCurrentWeek);
                dispatch(setDepartmentName(departmentName));
                dispatch(setDepartmentId(departmentId));
                setDataVisualMethod("Weekly");
                dispatch(setPlanShiftScreen("Weekly"));
                localStorage.setItem("planShiftScreen", "Weekly");
              }}
            >
              Weekly
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
        <Mui.Grid item md={4} lg={5.5}></Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          {dataVisualMethod === "Monthly" ? (
            <Mui.Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  views={["year", "month"]}
                  size="small"
                  sx={{ minWidth: "100%" }}
                  value={monthlyselectedDate}
                  minDate={minDate}
                  onChange={handleMonthlyDateOnChange}
                  slotProps={{ textField: { size: "small" } }}
                  renderInput={(params) => (
                    <Mui.TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Mui.Stack>
          ) : (
            <Mui.TextField
              label="Select Week"
              type="week"
              value={selectedWeek}
              size="small"
              sx={{ minWidth: "100%" }}
              onChange={handleWeeklyDateOnChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Mui.Grid>
        <Mui.Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <Mui.ButtonGroup>
            <Mui.Button
              variant={"contained"}
              sx={{
                background: themes.primaryButton,
                textTransform: "capitalize",
                mr: 2,
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor: themes.primaryButton,
                },
                color: themes.headLine,
              }}
              onClick={() => {
                history.push("/admin/teamzone/shift-group");
                dispatch(setGlobalSearchValue(""));
              }}
            >
              Shift Group
            </Mui.Button>
            <Mui.Button
              variant={"contained"}
              sx={{
                background: themes.primaryButton,
                textTransform: "capitalize",
                border: "none",
                mr: 2,
                "&:hover": {
                  border: "none",
                  backgroundColor: themes.primaryButton,
                },
                color: themes.headLine,
              }}
              onClick={() => {
                history.push("/admin/teamzone/shift-assignment");
                dispatch(setGlobalSearchValue(""));
              }}
            >
              Shift Assign
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid>
        {dataVisualMethod === "Weekly" ? (
          <Weekly
            loading={loading}
            weeklyShiftList={weeklyShiftList}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            shiftData={shiftData}
            listOfDate={listOfDate}
            updateWeeklyShift={updateShiftBasedOnRange}
          />
        ) : dataVisualMethod === "Monthly" ? (
          <Monthly
            loading={loading}
            monthlyShiftList={monthlyShiftList}
            filterChanges={filterChanges}
            setFilterChanges={setFilterChanges}
            shiftData={shiftData}
            listOfDate={listOfDate}
            updateMonthlyShift={updateShiftBasedOnRange}
          />
        ) : null}
      </Mui.Grid>
    </React.Fragment>
  );
};
