import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import { EditDeduction } from "./EditDeductions";
import { EditEarning } from "./EditEarnings";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
export const EditSalaryGroup = ({
  companyId,
  editSalaryeTemplateDetails,
  setCompanyFilter,
  setEditTemplate,
  companyFilter,
  reCallApi,
  setUpdateDeductionDetails,
  updateDeductionDetails,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [salaryGroupName, setSalaryGroupName] = useState(
    editSalaryeTemplateDetails?.salary_group_name
      ? editSalaryeTemplateDetails?.salary_group_name
      : ""
  );
  const [templateName, setTemplateName] = useState(
    editSalaryeTemplateDetails?.name ? editSalaryeTemplateDetails?.name : ""
  );
  const [earningDetailsData, setEarningDetailsData] = useState(
    editSalaryeTemplateDetails?.earnings
      ? editSalaryeTemplateDetails?.earnings
      : []
  );
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    editSalaryeTemplateDetails?.deductions
      ? editSalaryeTemplateDetails?.deductions
      : []
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDeduction] = useState(false);

  const { result: salaryGroupData, setLoad: reCallSourceBankApi } = useFetch(
    companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyFilter) {
      reCallSourceBankApi(true);
      setCompanyFilter(false);
    }
  }, [companyFilter, reCallSourceBankApi, setCompanyFilter]);

  useEffect(() => {
    setValue(
      "salary_template_name",
      editSalaryeTemplateDetails?.name ? editSalaryeTemplateDetails?.name : ""
    );
    setValue(
      "salary_group_id",
      editSalaryeTemplateDetails?.salary_group
        ? editSalaryeTemplateDetails?.salary_group
        : 0
    );
    setValue(
      "salary_group_name",
      editSalaryeTemplateDetails?.salary_group_name
        ? editSalaryeTemplateDetails?.salary_group_name
        : 0
    );

    setUserDeductionDetails(
      editSalaryeTemplateDetails?.deductions
        ? editSalaryeTemplateDetails?.deductions
        : []
    );
  }, [
    editSalaryeTemplateDetails?.deductions,
    editSalaryeTemplateDetails?.name,
    editSalaryeTemplateDetails?.salary_group,
    editSalaryeTemplateDetails?.salary_group_name,
    setValue,
  ]);

  const editSalaryeTemplate = async (data) => {
    const zeroAmoutEarnings = earningDetailsData.filter((data) => {
      return data?.amount === 0 || data?.amount === "0" || data.amount === "";
    });

    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount === 0 &&
        data.companyContributionAmount === 0
      );
    });
    setButtonDisabled(true);
    if (zeroAmoutEarnings.length === 0 && zeroAmoutDeductions.length === 0) {
      try {
        const response = await fetch(
          `${domain}salary-templates/${editSalaryeTemplateDetails.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              name: data.salary_template_name,
              salary_group: data.salary_group_id,
              earnings: earningDetailsData,
              deductions: userDeductionDetails.filter((data) => {
                return data.name !== "";
              }),
              company: companyId,
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEditTemplate(false);
          reCallApi(true);
          reset();
          showToast("success", "Salary template updated successfully");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonDisabled(false);
      }
    } else {
      setEmptyEarning(true);
      setEmptyDeduction(true);
      setButtonDisabled(false);
    }
  };

  const updateSalaryGroupName = (event) => {
    if (event.target.value.length < 32) {
      setValue("salary_template_name", event.target.value);
      setTemplateName(event.target.value);
    }
  };

  const handleSalaryGroupName = (event, data) => {
    if (data) {
      setSalaryGroupName(data.label);
      setValue("salary_group_id", data.id);
      setValue("salary_group_name", data.label);
    }
  };

  const checkSalaryTemplateName = useCallback(
    async (existsName) => {
      try {
        const response = await fetch(
          `${domain}check_salary_template_name/?salary_template_name=${existsName}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          if (res?.exists) {
            return "That salary template name is already taken.Please choose a unique name.";
          } else if (
            atleastOneCharacterWithSpace.test(existsName) ||
            existsName === ""
          ) {
            return true;
          } else {
            return "Atleast one alphabet required";
          }
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  return (
    <form onSubmit={handleSubmit(editSalaryeTemplate)}>
      <React.Fragment>
        <Mui.Grid container sx={{ padding: 5 }}>
          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                fontWeight: 700,
                paddingBottom: 0.5,
              }}
            >
              Salary Template Name
            </Mui.Typography>
            <CustomTextBox
              selectedValue={templateName}
              rules={{
                ...register("salary_template_name", {
                  required: "Salary template name is required",
                  validate: {
                    existsName: async (fieldValue) => {
                      if (
                        fieldValue !== "" &&
                        fieldValue !== editSalaryeTemplateDetails.name
                      ) {
                        return await checkSalaryTemplateName(fieldValue.trim());
                      } else {
                        return true;
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateSalaryGroupName}
              placeHolder="Enter salary template name"
              errorMessage={
                errors?.salary_template_name?.message
                  ? errors?.salary_template_name?.message
                  : ""
              }
              textBoxError={errors.salary_template_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.4}></Mui.Grid>

          <Mui.Grid xs={5.8}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                paddingBottom: 0.5,
              }}
            >
              Salary Group
            </Mui.Typography>
            <CustomAutoComplete
              dataList={salaryGroupData.map((obj) => ({
                ...obj,
                label: obj.name,
              }))}
              selectedValue={salaryGroupName}
              rules={{
                ...register("salary_group_name", {
                  required: "Salary group name is required",
                }),
              }}
              updateDataFunction={handleSalaryGroupName}
              placeHolder="Please select the salary group"
              errorMessage={
                errors?.salary_group_name?.message
                  ? errors?.salary_group_name?.message
                  : ""
              }
              textBoxError={errors?.salary_group_name ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ padding: 5 }}>
          <EditEarning
            companyId={companyId}
            earningDetailsData={earningDetailsData}
            setEarningDetailsData={setEarningDetailsData}
            emptyEarning={emptyEarning}
            setEmptyEarning={setEmptyEarning}
          />
          <EditDeduction
            companyId={companyId}
            userDeductionDetails={userDeductionDetails}
            setUserDeductionDetails={setUserDeductionDetails}
            earningDetailsData={earningDetailsData}
            emptyDeduction={emptyDeduction}
            setUpdateDeductionDetails={setUpdateDeductionDetails}
            updateDeductionDetails={updateDeductionDetails}
          />
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 3,
            paddingBottom: 2,
          }}
        >
          <CustomButton
            actionFuntion={() => setEditTemplate(false)}
            actionName="Cancel"
            typeName="button"
          />
          &nbsp; &nbsp;
          <CustomButton
            actionName="Update"
            typeName="submit"
            disableAction={buttonDisabled}
          />
        </Mui.Grid>
      </React.Fragment>
    </form>
  );
};
