import React, { useState, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { sitesettingsTeamZone } from "services/constants";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import { siteSettingsEditToastMessage } from "services/constants";
import { SiteSettingsPopup } from "./SiteSettingsPopup";
import {
  Typography,
  Stack,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Alert,
  Tooltip,
} from "@mui/material";

import TablePagination from "@mui/material/TablePagination";
import sessionHandling from "services/utils/notificationUtils";

export const SiteSettings = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [updateEditData, setUpdateEditData] = useState(false);
  // const [searchedValue, setSearchedValue] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userLocation, setUserLocation] = useState([]);
  const [overAllUserLocation, setOverAllUserLocation] = useState([]);
  const [editLocationDetails, setEditLocationDetails] = useState([]);
  const [update, setUpdate] = useState(true);
  const [masterData, setMasterData] = useState([]);

  const [dropDown, setDropDownMenu] = useState([
    "Edit",
    "Hold",
    "Reject",
    "Complete",
  ]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${domain}location_details/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setIsLoading(false);
          const locations = res.map((obj) => ({
            ...obj,
            username: obj.users[0]?.username,
          }));
          setOverAllUserLocation(locations);
          setUserLocation(locations);
          setMasterData(locations);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (update) {
      getData();
      setUpdate(false);
    }
  }, [domain, token, update]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setActionAnchorEl(null);
  };

  const handleMenuOpen = (event, data) => {
    if (data.location_status === "Approved") {
      setDropDownMenu(["Edit", "Hold", "Reject", "Complete"]);
    } else if (data.location_status === "Pending") {
      setDropDownMenu(["Edit", "Approve", "Reject", "Complete"]);
    } else if (data.location_status === "Hold") {
      setDropDownMenu(["Edit", "Approve", "Reject", "Complete"]);
    } else if (data.location_status === "Completed") {
      setDropDownMenu(["Edit", "Reject"]);
    } else if (data.location_status === "Rejected") {
      setDropDownMenu(["Edit", "Approve", "Hold", "Delete"]);
    }
    setActionAnchorEl(event.currentTarget);
  };

  const UpdateUserDetails = async (option) => {
    let location_status_handle = option;
    if (option === "Approve" || option === "Complete") {
      location_status_handle = option + "d";
    } else if (option === "Reject") {
      location_status_handle = option + "ed";
    }
    try {
      const response = await fetch(`${domain}edit_location/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          location_id: editLocationDetails.id,
          location_status: location_status_handle,
          city: editLocationDetails.city,
          location_name: editLocationDetails.location_name,
          address: editLocationDetails.address,
          tag: editLocationDetails.tag,
          pincode: editLocationDetails.pincode,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", siteSettingsEditToastMessage.editMessage);
        const field = "location_status";
        const updatedLocations = overAllUserLocation.map((element) => {
          if (element.id === editLocationDetails.id) {
            return {
              ...element,
              [field]:
                option === "Approve" || option === "Complete"
                  ? option + "d"
                  : option === "Reject"
                  ? option + "ed"
                  : option,
            };
          }
          return element;
        });
        setOverAllUserLocation(updatedLocations);
        setUserLocation(updatedLocations);
        setUpdate(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", "Error Update user location:", error);
    }
  };

  const deleteLocation = async () => {
    try {
      const response = await fetch(`${domain}location_details/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          location_id: editLocationDetails.id,
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Location deleted successfully");
        setUpdate(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const filteredResults = masterData.filter((item) =>
      sitesettingsTeamZone.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setUserLocation(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterData]);

  return (
    <React.Fragment>
      <Grid conatiner spacing={2} xs={12}>
        <TableContainer sx={{ padding: 1, width: "100%", minHeight: "95%" }}>
          {userLocation.length !== 0 ? (
            <Table
              className="border rounded"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 10,
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <TableRow maxWidth="xl" align="left">
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Site Name
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  ></TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Radius
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Tag
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Added by
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Site Status
                  </TableCell>
                  <TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLocation
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={data.id}>
                      <>
                        <TableCell align="left">{data.location_name}</TableCell>
                        <TableCell align="left">
                          <Stack>
                            <Typography>{data.address}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack sx={{ alignContent: "right" }}>
                            <Tooltip
                              title={data.latitude + " - " + data.longitude}
                              placement="right"
                            >
                              <IconButton
                                onClick={() =>
                                  window.open(
                                    `https://www.google.com/maps?q=${data.latitude},${data.longitude}&z=15`,
                                    "_blank"
                                  )
                                }
                              >
                                <AddLocationAltIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {parseInt(data.radius)}
                        </TableCell>
                        <TableCell align="left">{data.tag}</TableCell>
                        <TableCell align="left">
                          {data.users[0]?.username}
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            sx={{
                              color:
                                data.location_status === "Rejected"
                                  ? "Red"
                                  : data.location_status === "Hold"
                                  ? "Blue"
                                  : data.location_status === "Pending"
                                  ? "#a84248"
                                  : "Green",
                            }}
                          >
                            {data.location_status}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={(e) => {
                              handleMenuOpen(e, data);
                              setEditLocationDetails(data);
                            }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </IconButton>
                          <Menu
                            anchorEl={actionAnchorEl}
                            open={Boolean(actionAnchorEl)}
                            onClose={handleMenuClose}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {dropDown.map((option) => (
                              <Stack>
                                <MenuItem
                                  key={option}
                                  onClick={() => {
                                    if (
                                      option !== "Edit" &&
                                      option !== "Delete"
                                    ) {
                                      UpdateUserDetails(option);
                                      setActionAnchorEl(null);
                                    } else if (option === "Delete") {
                                      deleteLocation(option);
                                      setActionAnchorEl(null);
                                    } else {
                                      setUpdateEditData(true);
                                      setActionAnchorEl(null);
                                      setOpen(true);
                                    }
                                  }}
                                >
                                  {option}
                                </MenuItem>
                              </Stack>
                            ))}
                          </Menu>
                        </TableCell>
                      </>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : isLoading ? (
            <Loader info="Loading..." />
          ) : (
            <Alert severity="info">No data available</Alert>
          )}
        </TableContainer>
        {userLocation.length > 10 && (
          <TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={userLocation.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <SiteSettingsPopup
          open={open}
          handleClose={setOpen}
          title="Edit Site"
          editLocationDetails={editLocationDetails}
          setUpdateEditData={setUpdateEditData}
          updateEditData={updateEditData}
          setUpdate={setUpdate}
        />
      </Grid>
    </React.Fragment>
  );
};
