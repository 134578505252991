import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { teamZoneLoanHistory } from "pages/TeamZone/Approve/Headers";
const keys = [
  "principal_amount",
  "interest_rate",
  "tenure_months",
  "repayment_start_date",
  "repayment_end_date",
  "status",
  "user_name",
  "repayment_date",
  "disbursement_date",
  "description",
  "reason",
];

export const History = ({
  departmentChange,
  setDepartmentChange,
  userChange,
  setUserChange,
  departmentId,
  userId,
  loanScreen,
  setViewLoanDetails,
  setViewLoan
}) => {
  const { domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [loanHistory, setLoanHistory] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandDetails, setExpandDetails] = useState(null);
  const {
    result: loanDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}loans/?user_id=${userDetails.id}&approved_reject_status=Approved&department_id=${departmentId}&selected_user_id=${userId}`
  );
  console.log(loanDetails);

  useEffect(() => {
    if (
      (departmentChange === true || userChange === true) &&
      loanScreen === "History"
    ) {
      reCallApi(true);
      setDepartmentChange(false);
      setUserChange(false);
    }
  }, [
    departmentChange,
    loanScreen,
    reCallApi,
    setDepartmentChange,
    setUserChange,
    userChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanHistory(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    if (loanDetails?.length !== 0 || loanDetails?.length === 0) {
      const filteredResults = loanDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setLoanHistory(filteredResults);
      setExpandedRow(null);
      setPage(0);
    }
  }, [globalSearchValue, loanDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
              "& .MuiTableCell-root": {
                height: "15px",
                padding: "3px 5px",
              },
            }}
          >
            <Mui.TableRow>
              {teamZoneLoanHistory.map((head) => (
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">{head.name}</Mui.Typography>
                </Mui.TableCell>
              ))}
              {/* <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Employee Name</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Loan Amount</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Tenure</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Interest rate</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">EMI Amount</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Status</Mui.Typography>
              </Mui.TableCell> */}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {loanHistory.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={7}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              loanHistory
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <>
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "7px 5px",
                        },
                      }}
                    >
                      {teamZoneLoanHistory.map((head) =>
                        head.name !== "Action" ? (
                          <Mui.TableCell>
                            {item[head.key] ? item[head.key] : "N/A"}
                          </Mui.TableCell>
                        ) : (
                          <Mui.TableCell>
                            <Mui.IconButton
                              size="small"
                              onClick={() => {
                                setViewLoanDetails(item);
                                setViewLoan(true);
                              }}
                            >
                              <RemoveRedEyeIcon size="small" />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        )
                      )}
                      {/* <Mui.TableCell>
                        {expandedRow === item.id ? (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandedRow(null);
                            }}
                          >
                            <KeyboardArrowDownIcon
                              style={{
                                color: "black",
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandDetails(item.monthly_payments);
                              handleRowClick(item.id);
                            }}
                          >
                            <KeyboardArrowRightIcon
                              style={{
                                color: "black",
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell>{item?.user_name}</Mui.TableCell>
                      <Mui.TableCell>{item?.principal_amount}</Mui.TableCell>
                      <Mui.TableCell>{item?.tenure_months}</Mui.TableCell>
                      <Mui.TableCell> {item?.interest_rate}</Mui.TableCell>
                      <Mui.TableCell>
                        {item?.monthly_installment
                          ? item?.monthly_installment
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>{item.status}</Mui.TableCell> */}
                    </Mui.TableRow>
                    {expandedRow === item.id && item.status === "Approved" && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={7}>
                          <Mui.TableContainer>
                            <Mui.Table className="border">
                              <Mui.TableHead
                                sx={{
                                  backgroundColor: themes.primary,
                                  position: "sticky",
                                  top: -1,
                                  zIndex: 10,
                                  "& .MuiTableCell-root": {
                                    height: "15px",
                                    padding: "6px 35px",
                                  },
                                }}
                              >
                                <Mui.TableRow>
                                  <Mui.TableCell>
                                    <Mui.Typography fontWeight="bold">
                                      Date
                                    </Mui.Typography>
                                  </Mui.TableCell>
                                  <Mui.TableCell>
                                    <Mui.Typography fontWeight="bold">
                                      EMI Amount
                                    </Mui.Typography>
                                  </Mui.TableCell>
                                  <Mui.TableCell>
                                    <Mui.Typography fontWeight="bold">
                                      Status
                                    </Mui.Typography>
                                  </Mui.TableCell>
                                </Mui.TableRow>
                              </Mui.TableHead>
                              <Mui.TableBody>
                                {expandDetails.map((item) => (
                                  <Mui.TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        height: "15px",
                                        padding: "6px 35px",
                                      },
                                    }}
                                  >
                                    <Mui.TableCell>
                                      {item?.payment_month
                                        ? item?.payment_month
                                        : "N/A"}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      {item?.amount ? item?.amount : "N/A"}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      {" "}
                                      {item?.status ? item?.status : "N/A"}
                                    </Mui.TableCell>
                                  </Mui.TableRow>
                                ))}
                              </Mui.TableBody>
                            </Mui.Table>
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}

                    {expandedRow === item.id && item.status === "Rejected" && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={9}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0.5,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.Typography
                                variant="body1"
                                gutterBottom
                                sx={{ marginLeft: 1, marginTop: 0.5 }}
                              >
                                <strong>Description :</strong>{" "}
                                <Mui.Typography
                                  fontSize={15}
                                  color="textSecondary"
                                  sx={{ marginLeft: 13 }}
                                >
                                  {item.description}
                                </Mui.Typography>
                              </Mui.Typography>
                            </Mui.Table>
                          </Mui.TableContainer>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0.5,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.Typography
                                variant="body1"
                                gutterBottom
                                sx={{ marginLeft: 1, marginTop: 0.5 }}
                              >
                                <strong>Reason for Rejection :</strong>{" "}
                                <Mui.Typography
                                  fontSize={15}
                                  color="textSecondary"
                                  sx={{ marginLeft: 13 }}
                                >
                                  {item.reason}
                                </Mui.Typography>
                              </Mui.Typography>
                            </Mui.Table>
                          </Mui.TableContainer>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {loanHistory.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={loanHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
