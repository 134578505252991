import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const today = dayjs().format("YYYY-MM-DD");
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [selectedAdvanceLoanAmount, setSelectedAdvanceLoanAmount] =
    useState("");
  const [selectedReasonForAmount, setSelectedReasonForAmount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [paymentDate, setPaymentDate] = useState(today);

  const advanceType = [
    { id: 1, type: "Business" },
    { id: 2, type: "Personal" },
  ];

  const onSubmit = async (data) => {
    setButtonLoader(true);
    if (data) {
      try {
        const response = await fetch(`${domain}advances/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            user: userDetails?.id,
            amount: data?.advance_amount,
            repayment_date: data?.payment_date,
            description: data?.reason_for_amount,
            advance_type: data?.advance_type,
          }),
        });
        const res = await response.json();

        if (response.ok) {
          showToast(
            "success",
            "Advance request received! You'll get a notification when it's approved."
          );
          reset();
          setSelectedExpenseType("");
          setPaymentDate(today);
          setSelectedAdvanceLoanAmount("");
          setSelectedReasonForAmount("");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res?.error);
        }
      } catch (error) {
        showToast("error", error?.message);
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const updateAdvanceType = (event, data) => {
    if (data) {
      setValue("advance_type", data?.label);
      setSelectedExpenseType(data?.label);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedExpenseType("");
    setPaymentDate(today);
    setSelectedAdvanceLoanAmount("");
    setSelectedReasonForAmount("");
  };

  const updateAdvanceAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("advance_amount", event.target.value);
      setSelectedAdvanceLoanAmount(event.target.value);
    }
  };

  const updateReasonForAmount = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("reason_for_amount", event.target.value);
      setSelectedReasonForAmount(event.target.value);
    }
  };

  const validatePaymentDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid container>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Advance Type
          </Mui.Typography>
          <CustomAutoComplete
            dataList={advanceType?.map((obj) => ({
              ...obj,
              label: obj?.type,
            }))}
            rules={{
              ...register("advance_type", {
                required: "Advance type is required",
              }),
            }}
            selectedValue={selectedExpenseType}
            updateDataFunction={updateAdvanceType}
            placeHolder="Please select the advance type"
            errorMessage={
              errors?.advance_type?.message ? errors?.advance_type?.message : ""
            }
            textBoxError={errors?.advance_type ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Advance Amount
          </Mui.Typography>
          <CustomTextBox
            rules={{
              ...register("advance_amount", {
                required: "Amount is required",
              }),
            }}
            selectedValue={selectedAdvanceLoanAmount}
            updateDataFunction={updateAdvanceAmount}
            placeHolder="Enter advance amount"
            errorMessage={
              errors?.advance_amount?.message
                ? errors?.advance_amount?.message
                : ""
            }
            textBoxError={errors?.advance_amount ? true : false}
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid xs={12} sx={{ padding: 1 }}>
        <Mui.Typography
          sx={{
            fontSize: 20,
            color: themes.headLine,
            padding: 1,
          }}
        >
          Payment Date
        </Mui.Typography>
        <Mui.TextField
          fullWidth
          size="small"
          type="date"
          id="payment_date"
          value={paymentDate}
          {...register("payment_date", {
            required: "Date is required.",
            validate: validatePaymentDate,
          })}
          error={!!errors.payment_date}
          helperText={errors.payment_date && errors.payment_date.message}
          onChange={(event) => {
            setPaymentDate(event.target.value);
          }}
        />
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Typography
          sx={{
            fontSize: 20,
            color: themes.headLine,
            padding: 1,
          }}
        >
          Reason for amount
        </Mui.Typography>
        <CustomTextBox
          type="multiline"
          rules={{
            ...register("reason_for_amount", {
              required: "Reason for amount is required",
              validate: {
                minLengthNoSpaces: (value) => {
                  if (value?.trim().length < 3 && value !== "") {
                    return minimum3Characters;
                  } else if (value?.trim().length > 255 && value !== "") {
                    return "Maximum length is 255 characters";
                  } else {
                    return true;
                  }
                },
                atleastOneCharacterWithSpaceReg: (value) => {
                  if (
                    atleastOneCharacterWithSpace.test(value) ||
                    value === ""
                  ) {
                    return true;
                  } else {
                    return "Atleast one alphabet required";
                  }
                },
              },
            }),
          }}
          selectedValue={selectedReasonForAmount}
          updateDataFunction={updateReasonForAmount}
          placeHolder="Enter the reason for amount"
          errorMessage={
            errors?.reason_for_amount?.message
              ? errors?.reason_for_amount?.message
              : ""
          }
          textBoxError={errors?.reason_for_amount ? true : false}
        />
      </Mui.Grid>
      <Mui.Grid
        container
        spacing={1}
        sx={{
          paddingTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        <Mui.Grid item>
          <CustomButton
            actionFuntion={handleClearButtonClick}
            actionName="Clear"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Grid item>
          <CustomButton
            actionName="Apply"
            typeName="submit"
            disableAction={buttonLoader}
          />
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
