import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { loanTypeArray, interestType } from "../Headers";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { rgxWithDot, onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";

export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const today = dayjs().format("YYYY-MM-DD");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [selectedInterestType, setSelectedInterestType] = useState("");
  const [loanType, setLoanType] = useState("");
  const [descriptions, setDescriptions] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
  const [loanInterestPercentage, setLoanInterestPercentage] = useState("");
  const [tenureMonthCount, setTenureMonthCount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [installmentAmount,setInstallmentAmount]=useState(0);
  const [totalPrincipalAmount,setTotalPrincipalAmount] = useState(0);
  const [interestAmount,setInterestAmount] = useState(0);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}loans/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user: userDetails.id,
          principal_amount: data.loan_amount,
          interest_rate: data.interest,
          tenure_months: data.tenure,
          repayment_start_date: data.from_date,
          monthly_installment: data.emi_amount,
          description: data.descriptions,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          "Loan request received! You'll get a notification when it's approved."
        );
        clearAllData();
        setStartDate(today);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const clearAllData = () => {
    setDescriptions("");
    setLoanAmount("");
    setEmiAmount("");
    setStartDate(today);
    setLoanInterestPercentage("");
    setTenureMonthCount("");
    reset();
  };

  const updateLoanAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("loan_amount", event.target.value);
      setLoanAmount(event.target.value);
      calculateEMIAmount();
    }
  };

  const updateDesciptions = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("descriptions", event.target.value);
      setDescriptions(event.target.value);
    }
  };

  const updateInterestPercentage = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("interest", event.target.value);
      setLoanInterestPercentage(event.target.value);
      calculateEMIAmount();
    } else {
      event.preventDefault();
    }
  };

  const updateEMIAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("emi_amount", event.target.value);
      setEmiAmount(event.target.value);
      calculateEMIAmount();
    }
  };

  const updateTenureMonth = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 2
    ) {
      setValue("tenure", event.target.value);
      setTenureMonthCount(event.target.value);
      calculateEMIAmount();
    } else {
      event.preventDefault();
    }
  };

  const calculateEMIAmount = async () => {
    let tenure = parseInt(getValues("tenure"));
    let principalAmount = parseInt(getValues("loan_amount"));
    let interest = parseFloat(getValues("interest")).toFixed(2);
    if (tenure && principalAmount && interest) {
      let R = parseFloat(interest) / 12 / 100;
      let PR = principalAmount * R;
      let EMI = (PR * Math.pow(1 + R, tenure)) / [Math.pow(1 + R, tenure) - 1];
      setValue("emi_amount", EMI ? Math.round(EMI) : 0);
      setEmiAmount(EMI ? Math.round(EMI) : 0);
    }
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return true;
  };

  const handleLoanType = (event, data) => {
    if (data) {
      setValue("loan_type", data?.label);
      setLoanType(data?.label);
    } else {
      setValue("loan_type", null);
      setLoanType("");
    }
  };

  const handleInterestType = (event, data) => {
    if (data) {
      setValue("loan_type", data?.label);
      setSelectedInterestType(data?.label);
    } else {
      setValue("loan_type", null);
      setSelectedInterestType("");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{ border: `2px solid ${themes.sidebarDivider}`, borderRadius: 2 }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              padding: 1,
              backgroundColor: themes.sidebarDivider,
            }}
          >
            <Mui.Typography
              sx={{ paddingLetf: 2, fontSize: 20, fontWeight: "bold" }}
            >
              Loan Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Employee Id & Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.TextField
                      value="32875434"
                      size="small"
                      disabled={true}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={8} sx={{ paddingLeft: 1 }}>
                    <Mui.TextField
                      value={userDetails.first_name}
                      size="small"
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={loanTypeArray}
                  selectedValue={loanType}
                  rules={{
                    ...register("loan_type", {
                      required: "Loan type is required",
                    }),
                  }}
                  updateDataFunction={handleLoanType}
                  placeHolder="Please select the loan type"
                  errorMessage={
                    errors?.loan_type?.message ? errors?.loan_type?.message : ""
                  }
                  textBoxError={errors?.loan_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={loanAmount}
                  rules={{
                    ...register("loan_amount", {
                      required: "Loan amount is required",
                    }),
                  }}
                  updateDataFunction={updateLoanAmount}
                  placeHolder="Enter loan amount"
                  errorMessage={
                    errors?.loan_amount?.message
                      ? errors?.loan_amount?.message
                      : ""
                  }
                  textBoxError={errors.loan_amount ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={interestType}
                  selectedValue={selectedInterestType}
                  rules={{
                    ...register("interest_type", {
                      required: "Interest type is required",
                    }),
                  }}
                  updateDataFunction={handleInterestType}
                  placeHolder="Please select the interest type"
                  errorMessage={
                    errors?.interest_type?.message
                      ? errors?.interest_type?.message
                      : ""
                  }
                  textBoxError={errors?.interest_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={loanInterestPercentage}
                  rules={{
                    ...register("interest", {
                      required: "Interest percentange is required",
                      pattern: {
                        value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                      },
                      validate: {
                        checkAmount: (fieldValue) => {
                          const value = parseFloat(fieldValue);
                          if (isNaN(value) || value < 0 || value > 100) {
                            return "Interest must be less then 100 %";
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateInterestPercentage}
                  placeHolder="Enter the loan interest percentage"
                  errorMessage={
                    errors?.interest?.message ? errors?.interest?.message : ""
                  }
                  textBoxError={errors.interest ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Tenure(Month)
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={tenureMonthCount}
                  rules={{
                    ...register("tenure", {
                      required: "Tenure month is required",
                      pattern: {
                        value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                      },
                      validate: {
                        checkAmount: (fieldValue) => {
                          const value = parseFloat(fieldValue);
                          if (isNaN(value) || value < 0 || value > 100) {
                            return "Tenure month must be less then 100";
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateTenureMonth}
                  placeHolder="Enter the tenure month"
                  errorMessage={
                    errors?.tenure?.message ? errors?.tenure?.message : ""
                  }
                  textBoxError={errors.tenure ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Reason for loan
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: "100%",
                    // marginRight: "auto",
                  }}
                  id="description"
                  {...register("description", {
                    required: "Description is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  label="Provide a reason for loan"
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description && errors.description.message}
                  //   onBlur={() => {
                  //     trigger("description");
                  //   }}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionName="Calculate"
                typeName="submit"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {/* second forms */}
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{
            border: `2px solid ${themes.sidebarDivider}`,
            borderRadius: 2,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              backgroundColor: themes.sidebarDivider,
              padding: 1,
            }}
          >
            <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Installment Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Installment
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField value={installmentAmount} size="small" fullWidth />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Total Principal
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                value={totalPrincipalAmount}
                size="small"
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Interest Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField value={interestAmount} size="small" fullWidth />
              {/* <CustomTextBox
                selectedValue={emiAmount}
                rules={{
                  ...register("emi_amount", {
                    required: "EMI amount is required",
                  }),
                }}
                updateDataFunction={updateEMIAmount}
                placeHolder="Enter EMI amount"
                errorMessage={
                  errors?.emi_amount?.message ? errors?.emi_amount?.message : ""
                }
                textBoxError={errors.emi_amount ? true : false}
              /> */}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Loan Start Date (Exp)
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={startDate}
                {...register("from_date", {
                  required: "Date is required.",
                  validate: validateStartDate,
                })}
                error={!!errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Loan Completion Date (Exp)
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={startDate}
                {...register("from_date", {
                  required: "Date is required.",
                  validate: validateStartDate,
                })}
                error={!!errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionName="Cancel"
                typeName="button"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Create"
                typeName="submit"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
    // </Mui.Grid>
  );
};
